import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
          main: "#12B5A0",
          contrastText: "#F3EFE0"
        },
        secondary: {
          main: "#0B2545",
          contrastText: "#F3EFE0"
        },
        base: {
          main: "#134074",
          contrastText: "#F3EFE0"
        },
        error: {
          main: "#EC534E",
          contrastText: "#F3EFE0"
        },
        contrastText: {
            main: "#F3EFE0"
        }
    }
});

export default theme;